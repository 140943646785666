'use strict';

require('./vendor');

import Cookies from 'js-cookie';

import { Fancybox } from "@fancyapps/ui/dist/fancybox/fancybox.esm.js";


jQuery(document).ready(function ($) {

  var root = $('body');
  $('#stickybtn-meerwerk').addClass('hide');

  // For modals

  Fancybox.bind("[data-fancybox]", {
    // Your custom options
  });

  // NAV

  function scrolled() {
    if (jQuery(document).scrollTop() > 50) {
      $('body').addClass('scrolled');
    } else {
      $('body').removeClass('scrolled');
    }

    if (jQuery(document).scrollTop() > 270) {
      $('#stickybtn-meerwerk').removeClass('hide');
      $('.fctbtn-mob').removeClass('hide');
    } else {
      $('#stickybtn-meerwerk').addClass('hide');
      $('.fctbtn-mob').addClass('hide');
    }

  };

  function progressBar() {
    if ($('#wrapper').length) {
      var scrollTop = $(window).scrollTop();
      var documentHeight = (($('#wrapper').outerHeight() + $('#wrapper').offset().top) - $(window).height());
      var progressBar = (scrollTop / documentHeight) * 100;
      $('#progress-bar').css('width', progressBar + '%');
    }
  }

  function stickyOfferte() {
    if (jQuery(document).scrollTop() > 250) {
      $('#cta-1').addClass('inactive');
      $('#cta2').removeClass('inactive');

    } else {
      $('#cta2').addClass('inactive');
      $('#cta-1').removeClass('inactive');

    }
  };
  scrolled();
  progressBar();

  $(window).scroll(function () {

    scrolled();
    progressBar();
  });

  // Faq
  $(".faq .antwoord").hide();
  $(".faq .vraag").click(function (evt) {
    var target = $(evt.target);
    if (target.parent('a').length) {
      //gewone link
    } else {
      $(this).next(".faq .antwoord").slideToggle(500);
      $(this).toggleClass("expanded");
    }
  });

  //extra content home
  $(".cntwrapper").hide();
  $(".cntkop").on('click', function () {
    console.log('klik');
    $(this).next(".cntwrapper").slideToggle(500);
    $(this).toggleClass("expanded");

  });


  if ($('.detail-button').length > 0) {
    var buttonContainer = $('.detail-button');
    var ctaoffset = buttonContainer.offset().top

    jQuery(document).on('scroll', function () {
      if (jQuery(document).scrollTop() >= ctaoffset) {
        buttonContainer.addClass('fixed');
      } else {
        buttonContainer.removeClass('fixed');
      }
    });
  }

  // Form
  $('form').on("submit", function () {
    $(this).find('.loader').addClass('active');
  });

  // Placeholders in formulieren
  var isInputSupported = 'placeholder' in document.createElement('input');
  var isTextareaSupported = 'placeholder' in document.createElement('textarea');
  if (!isInputSupported || !isTextareaSupported) {
    $('[placeholder]').focus(function () {
      var input = $(this);
      if (input.val() === input.attr('placeholder') && input.data('placeholder')) {
        input.val('');
        input.removeClass('placeholder');
      }
    }).blur(function () {
      var input = $(this);
      if (input.val() === '') {
        input.addClass('placeholder');
        input.val(input.attr('placeholder'));
        input.data('placeholder', true);
      } else {
        input.data('placeholder', false);
      }
    }).blur().parents('form').submit(function () {
      $(this).find('[placeholder]').each(function () {
        var input = $(this);
        if (input.val() === input.attr('placeholder') && input.data('placeholder')) {
          input.val('');
        }
      });
    });
  }

  // Select dienst in form
  var dienst = $('.header .intro h1 span').html();
  $('select[name="dienst"] option:contains(' + dienst + ')').prop('selected', true);

  $('.intro a.slide:nth-child(1)').addClass('active');

  setInterval(function () {
    if ($('.intro a.slide:last').hasClass('active')) {
      $('.intro a.slide.active').addClass('slide-out').removeClass('active');
      $('.intro a.slide:first-child').addClass('active');
      setTimeout(function () {
        $('.intro a.slide.slide-out').removeClass('slide-out');
      }, 500);
    } else {
      $('.intro a.slide.active').addClass('slide-out').removeClass('active').next('a.slide').addClass('active');
      setTimeout(function () {
        $('.intro a.slide.slide-out').removeClass('slide-out');
      }, 500);
    }
  }, 3000);

  // Header button

  $('.header .meerweten').click(function (e) {
    e.preventDefault();
    $('html,body').animate({
      scrollTop: 700
    });
  });

  $('a.scrollto').click(function (e) {
    e.preventDefault();
    var dest = $(this).attr('href').split("#")[1];
    //console.log(dest);
    $('html,body').animate({
      scrollTop: $('#' + dest).offset().top - 70
    }, 'slow');
  });

  $('.button').each(function () {
    var text = $(this).text();
    $(this).html('<span>' + text + '</span>');
  });

  // MENU
  $('#indexbtn').on('click', function () {
    root.toggleClass('overflow');
    $('#mobile-nav').toggleClass('active');
    $(this).toggleClass('open');
  });


  // SEARCH
  $('.search-button, .search-button-mob').on('click', function (e) {
    e.preventDefault();
    $('body').toggleClass('search-open');
    setTimeout(function () {
      $('input[type="text"]').focus();
    }, 500);
  });

  $('#mobile-nav a.has-submenu').click(function (i) {
    i.preventDefault();

  });
  $('ul.subnav').closest('li').click(function (e) {
    $(this).toggleClass('open');

  });


  // OFFERTE AANVRAGEN
  $('.offerteadres').hide();
  $('.offerteoverige').hide();
  $('.offertevestiging').hide();
  $('.offertetype').change(function () {
    if ($('#offerte1, #offerte2, #offerte3').is(':selected')) {
      $('.offerteadres').slideDown();
    } else {
      $('.offerteadres').slideUp();
    }

    if ($('#offerte5').is(':selected')) {
      $('#woonplaats').slideUp();
      $('#vestiging').slideDown();

    } else {
      $('#woonplaats').slideDown();
      $('#vestiging').slideUp();
    }

    if ($('#offerte6').is(':selected')) {
      $('.offerteoverige').slideDown();
    } else {
      $('.offerteoverige').slideUp();
    }
  });
  //$('#popup').foundation('open');
  // Cookies
  if (Cookies.get('popup') != 'ja') {
    $('#popup').foundation('open');
    Cookies.set('popup', 'ja', {
      expires: 7,
      path: '/'
    });
  }
});

$(document).foundation();

if (module.hot) {
  module.hot.accept()
}